<template>
  <div
    :class="style"
    class="avatar flex-shrink-0 fs-6 font-monospace"
  >
    {{ initials }}
    <img v-if="user.photoUrl" :src="user.photoUrl">
  </div>
</template>

<script>
export default {
  name: 'UserAvatar',
  props: {
    user: {
      type: Object,
      required: true,
    },
    variant: {
      type: String,
      default: 'default',
    },
  },
  computed: {
    style() {
      if (this.variant === 'small') {
        const { photoUrl } = this.user;
        if (!photoUrl || photoUrl === '') {
          return 'd-none';
        }
        return 'avatar-small';
      }
      if (this.variant === 'medium') {
        return 'avatar-medium';
      }
      if (this.variant === 'big') {
        return 'avatar-big';
      }
      return '';
    },
    initials() {
      const { firstName, lastName } = this.user;
      return `${firstName[0]}${lastName && lastName[0] ? lastName[0] : ''}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/vars.scss";

$size: 3rem;

.avatar {
  cursor: default;
  position: relative;
  width: $size;
  height: $size;
  line-height: $size;
  text-align: center;
  border-radius: 50%;
  background-position: 50% 50%;
  background-size: cover;
  background-color: $secondarySystemBackground;
  color: $secondaryLabel;
  overflow: hidden;
  pointer-events: none;

  &.avatar-small {
    width: 1rem;
    height: 1rem;
    line-height: 1rem;
    font-size: .5rem !important;
  }

  &.avatar-medium {
    width: 1.75rem;
    height: 1.75rem;
    line-height: 1.75rem;
    font-size: .75rem !important;
  }

  &.avatar-big {
    width: $size * 2;
    height: $size * 2;
    line-height: $size * 2;
    font-size: 2rem !important;
  }

  img {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
